import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../../images/undersidor/skanningsprogramvara.svg";
import fakturadata_img from '../../images/undersidor/fakturadata.png';

const KONTAKT_BOT = "kxFV5wCG";

function Fakturakontroll() {
    const [kontakt_1, set_kontakt_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Invoice control | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Control invoice data more efficiently" />
                <meta name="keywords" content="CrossState eVoyce" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Control invoice data more efficiently" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/supplier-invoices/invoice-control" />
                <link rel="cannonical" href="https://www.crediflow.com/supplier-invoices/invoice-control" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Check invoice data and teach the system to be more efficient</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        CrossState eVoyce is a self-learning system, which means 
                                        that you do not have to spend time creating templates 
                                        for how invoices should be interpreted and that the 
                                        interpretation becomes more secure as invoices are handled.
                                    </p>
                                    <button onClick={() => set_kontakt_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                                    {kontakt_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={KONTAKT_BOT} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:w-1/3 lg:w-1/2 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="Control invoice data more efficiently" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Home</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <Link to="/supplier-invoices/" className="flex items-center text-blue-800 hover:text-blue-600">supplier-invoices</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">invoice-control</span>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-12 pb-32" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <p className="leading-relaxed text-lg text-gray-600">
                                In the beginning, the system will ask you to check 
                                fields that the application is unsure about or 
                                manually add information if the uncertainty makes 
                                it not want to suggest a value. When the check is 
                                done, knowledge is created about how the invoice 
                                should be handled next time. As knowledge is built 
                                and used, the service becomes more secure and 
                                control is reduced to the point where you only 
                                give an OK to the invoice with the push of a button.
                                <br/><br/>
                                The control ensures that the data delivered to 
                                your next system is always correct and that 
                                the application learns, gets better and saves you time.
                                <br/><br/>
                                If you want to do the check yourself, you get a 
                                login and training in our system, otherwise 
                                you let us handle the work.
                                <br/><br/>
                                We can also divide your flow so that, for example, 
                                we handle your cost invoices while you check goods 
                                invoices with orders and items that are to 
                                be linked to purchases or vice versa.
                                <br/><br/>
                                We are flexible according to how you want it.
                            </p>
                            <Link to="/demo/" className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Book Demo</Link>
                        </div>
                
                        <div className="w-full sm:w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={fakturadata_img} alt="Invoice control" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Fakturakontroll;